import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./projects-hero.yaml"

const ProjectsHero = () => {
  return (
    <section className="projects_area pad_btm">
      <Container className="p0" fluid>
        <Row className="no-gutters">
          <Col className="projects_title" lg={6} xl={7}>
            <h2>{YAMLdata.title}</h2>
          </Col>
          <Col lg={6} xl={5}></Col>
        </Row>
        <Row className="no-gutters">
          <Col lg={6} xl={7} className="projects_categories pad_btm">
            <div className="projects_box">
              <div className="project_img">
                <div className="prj-img-ph">
                  <StaticImage
                    className="hero_cat_img"
                    src="../images/projects/projects-hero.jpg"
                    alt="WDA Projects"
                    objectFit="cover"
                    layout="fullWidth"
                  />
                </div>
              </div>
              <div className="project_cats">
                <div className="category_row">
                  <div className="cat_head fnb">
                    <h3>F&amp;B</h3>
                  </div>
                  <div className="cat_projects">
                    <div className="project_subcat link1">
                      <Link
                        to="/projects/bars-&amp;-lounges"
                        className="cta-categ"
                      >
                        Bars // Lounges
                      </Link>
                      <div className="subcat_icon">
                        <StaticImage
                          src="../images/projects/bar-loungue-icon.svg"
                          alt="Bars / Lounges"
                        />
                      </div>
                    </div>
                    <div className="project_subcat link2">
                      <Link
                        to="/projects/restaurants-&amp;-eateries"
                        className="cta-categ"
                      >
                        Restaurants // Eateries
                      </Link>
                      <div className="subcat_icon">
                        <StaticImage
                          src="../images/projects/restaurants-eateries-icon.svg"
                          alt="Restaurants / Eateries"
                        />
                      </div>
                    </div>
                    <div className="project_subcat link3">
                      <Link
                        to="/projects/microbreweries-&amp;-taprooms"
                        className="cta-categ"
                      >
                        Microbreweries // Taprooms
                      </Link>
                      <div className="subcat_icon">
                        <StaticImage
                          src="../images/projects/microbreweries-taprooms-icon.svg"
                          alt="Microbreweries / Taprooms"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="category_row">
                  <div className="cat_head lei">
                    <h3>Leisure</h3>
                  </div>
                  <div className="cat_projects">
                    <div className="project_subcat link4">
                      <Link to="/projects/hospitality" className="cta-categ">
                        Hospitality
                      </Link>
                      <div className="subcat_icon">
                        <StaticImage
                          src="../images/projects/hospitality-icon.svg"
                          alt="Hospitality"
                        />
                      </div>
                    </div>
                    <div className="project_subcat link5">
                      <Link to="/projects/entertainment" className="cta-categ">
                        Entertainment
                      </Link>
                      <div className="subcat_icon">
                        <StaticImage
                          src="../images/projects/entertainment-icon.svg"
                          alt="Entertainment"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="category_row">
                  <div className="cat_head lux">
                    <h3>Luxury</h3>
                  </div>
                  <div className="cat_projects">
                    <div className="project_subcat link6">
                      <Link to="/projects/retail" className="cta-categ">
                        Retail
                      </Link>
                      <div className="subcat_icon">
                        <StaticImage
                          src="../images/projects/retail-icon.svg"
                          alt="Retail"
                        />
                      </div>
                    </div>
                    <div className="project_subcat link7">
                      <Link to="/projects/workspaces" className="cta-categ">
                        Workspaces
                      </Link>
                      <div className="subcat_icon">
                        <StaticImage
                          src="../images/projects/workspaces-icon.svg"
                          alt="Workspaces"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} xl={5} className="no-gutters">
            <div className="products_content">
              <div className="projects_content_ph">
                <p className="black-dash">
                  <span>{YAMLdata.content}</span>
                </p>
                <p className="sub-head">{YAMLdata.subhead} </p>
                {YAMLdata.subcopy.map((txt, ind) => {
                  return (
                    <p key={`scopy${ind}`} className="sub-copy">
                      {txt}
                    </p>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default ProjectsHero
