import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import ClientsList from "../../sections/clients-list"
import ProjectsHero from "../../sections/projects-hero"

const Projects = () => (
  <Layout>
    <Seo
      title="Projects"
      description="We specialise in F&amp;B, Leisure and Luxury spaces - crafting a complete guest experience through a multidisciplinary approach."
    />
    <ProjectsHero />
    <ClientsList />
  </Layout>
)

export default Projects
